<template>
  <div id="carTravel">车载出行绩效分析</div>
</template>

<script>
export default {
  data() {
      return {

      }
  }
}
</script>

<style lang="scss">
#carTravel {
}
</style>
